import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Row,
  Table,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  Button,
} from "reactstrap";
import "./styles/businessBackofficeStyles.scss";

import { getMantisBusinessNotifications } from "../ApiCalls/mantisBusiness";
import { getMonthAbreviation, getMonthName } from "../utils/dateUtils";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
  };
};

function BusinessNotifications(props) {
  const [mantisNotifications, setMantisNotifications] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getBusinessNotifications();
  }, []);

  async function getBusinessNotifications() {
    const res = await getMantisBusinessNotifications(
      props.mantisBusiness._id,
      props.token
    );
    if (res.success) {
      setMantisNotifications(res.notifications);
    }
    setLoading(false);
  }

  return (
    <div>
      <Row>
        <Col
          xs={11}
          style={{
            margin: "auto",
          }}
        >
          <Row>
            <Col xs={6}>
              <h1 className="notifications-title">Notificaciones</h1>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={11} style={{ margin: "auto" }}>
          <div>
            {loading ? (
              <Spinner color="#282828" />
            ) : mantisNotifications.length === 0 ? (
              <h3 className="business-notifications-date">
                ¡No tienes notificaciones por el momento!
              </h3>
            ) : (
              mantisNotifications.map((date, index) => {
                let label = date.label;
                let day = parseInt(label.split("-")[0]);
                let month = parseInt(label.split("-")[1]);
                let year = parseInt(label.split("-")[2]);
                let notifications = date.notifications;
                return (
                  <div key={index}>
                    {/* <h3 className="business-notifications-date">
                      📆 {day} de {getMonthName(month)}
                    </h3> */}
                    {notifications.map(
                      (mantisNotification, notificationIndex) => {
                        let type = mantisNotification.type;
                        if (
                          type === "NEW_FOLDER_FROM_EMPLOYEE" ||
                          type === "NEW_DOCUMENT_FROM_EMPLOYEE"
                        ) {
                          return (
                            <>
                              <Row
                                key={notificationIndex}
                                className="business-notification-container"
                              >
                                <Col xs={10}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      alignContent: "center",
                                    }}
                                  >
                                    {!mantisNotification.read && (
                                      <div
                                        style={{
                                          height: 5,
                                          width: 5,
                                          borderRadius: 5,
                                          backgroundColor: "#282828",
                                          marginRight: 6,
                                        }}
                                      ></div>
                                    )}
                                    <h4
                                      className="business-notification-subject"
                                      style={
                                        !mantisNotification.read
                                          ? { fontWeight: 600 }
                                          : { fontWeight: 400 }
                                      }
                                    >
                                      {mantisNotification.icon}{" "}
                                      {mantisNotification.subject}
                                    </h4>
                                  </div>
                                  <h4 className="business-notification-message">
                                    {mantisNotification.message}
                                  </h4>
                                  <p className="business-notifications-additional-info">
                                    {mantisNotification.locationDescription}
                                  </p>
                                </Col>
                                <Col xs={2}>
                                  <h4 className="business-notification-message">
                                    {getMonthAbreviation(month)} {day}
                                  </h4>
                                </Col>
                              </Row>
                              <hr />
                            </>
                          );
                        } else if (
                          type === "MANTIS_BUSINESS_CONNECTION_STATUS_CHANGE"
                        ) {
                          return (
                            <>
                              <Row
                                key={notificationIndex}
                                className="business-notification-container"
                              >
                                <Col xs={10}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      alignContent: "center",
                                    }}
                                  >
                                    {!mantisNotification.read && (
                                      <div
                                        style={{
                                          height: 5,
                                          width: 5,
                                          borderRadius: 5,
                                          backgroundColor: "#282828",
                                          marginRight: 6,
                                        }}
                                      ></div>
                                    )}
                                    <h4
                                      className="business-notification-subject"
                                      style={
                                        !mantisNotification.read
                                          ? { fontWeight: 600 }
                                          : { fontWeight: 400 }
                                      }
                                    >
                                      {mantisNotification.icon}{" "}
                                      {mantisNotification.subject}
                                    </h4>
                                  </div>
                                  <h4 className="business-notification-message">
                                    {mantisNotification.message}
                                  </h4>
                                </Col>
                                <Col xs={2}>
                                  <h4 className="business-notification-message">
                                    {getMonthAbreviation(month)} {day}
                                  </h4>
                                </Col>
                              </Row>
                              <hr />
                            </>
                          );
                        }
                      }
                    )}
                  </div>
                );
              })
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default connect(select)(BusinessNotifications);
