// src/MantisBusiness/Notifications.jsx
import React, { useState } from "react";
import UserNotification from "./UserNotification"; 
import "./styles/businessBackofficeStyles.scss";

function Notifications() {
  const [showNotifications, setShowNotifications] = useState(false);

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  return (
    <div>
      <div className="notification-bell" onClick={toggleNotifications}>
        🔔
      </div>
      {showNotifications && (
        <div className="notifications-popup">
          <UserNotification />
        </div>
      )}
    </div>
  );
}

export default Notifications;