import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "./styles/businessBackofficeStyles.scss";
import {
  priorityOptions,
  documentOptions,
  employees,
  tipificationOptions,
  groupOptions,
  dependenceOptions,
  colombianCities,
  documentTypeOptions,
  responseMediumOptions,
  classesOptions,
} from "../config";
import { backUrl } from "../utils/backURL";
import { ModalBody } from "react-bootstrap";

function DocumentActions() {
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${day}/${month}/${year}`;
  };
  const [radicado, setRadicado] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [formValues, setFormValues] = useState({
    "Radicado": "",
    "Fecha de Ingreso": getCurrentDate(),
    "Prioridad": priorityOptions[0]?.value || "",
    "Grupo": groupOptions[0]?.value || "",
    "Clase Correspondencia": classesOptions[0]?.value || "",
    "Tipificación": tipificationOptions[0]?.value || "",
    "Medio Recepción": documentOptions[0]?.value || "",
    "Para": employees[0]?.value || "",
    "Dependencia": dependenceOptions[0]?.value || "",
    "Asunto": "",
    "No. Hojas": "",
    "Número de Comunicación": "",
    "Nombre Remitente": "",
    "Tipo de Documento": documentTypeOptions[0]?.value || "",
    "Número de Documento": "",
    "Dirección": "",
    "Email": "",
    "No. Celular": "",
    "País": "COLOMBIA",
    "Ciudad": colombianCities[0]?.value || "",
    "Fecha de Remisión": "",
    "Medio de Respuesta": responseMediumOptions[0]?.value || "",
    "Copias del Rótulo": "",
    "Rótulo automático": true,
    "Rótulo papel": false,
    "Requiere Respuesta": false,
  });
  const [predictedFields, setPredictedFields] = useState([]);

  useEffect(() => {
    generateRadicado();
  }, []);

  const generateRadicado = () => {
    const date = new Date();
    const year = date.getFullYear().toString().slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const sequentialNumber = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, "0");

    const radicadoNumber = `${year}${month}${sequentialNumber}`;
    setRadicado(radicadoNumber);
    setFormValues((prev) => ({ ...prev, "Radicado": radicadoNumber }));
  };

  const handleFileChange = async (event) => {
    const selectedFiles = event.target.files;
    const filePromises = Array.from(selectedFiles).map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result.replace(
            /^data:application\/pdf;base64,/,
            ""
          );
          resolve({ name: file.name, base64: base64String });
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });

    try {
      const filesData = await Promise.all(filePromises);
      setFiles(filesData);
      if (!isPreviewModalOpen) { 
        setIsPreviewModalOpen(true);
      }
      console.log("PREVIEW MODAL OPEN", isPreviewModalOpen);
      await handleSubmitFiles(filesData);
    } catch (error) {
      console.error("Error processing files:", error);
    } finally {
      setLoading(false); 
    }
  };
  const handleSubmitForm = async () => {
    const url = `${backUrl}/form`;

    try {
      setLoading(true);
      const formResponse = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ formData: formValues }),
      });

      if (!formResponse.ok) {
        throw new Error("Error al enviar los datos del formulario");
      }
      const formData = await formResponse.json();
      console.log("Respuesta del servidor para datos del formulario:", formData);
      setIsPreviewModalOpen(false);
    } catch (error) {
      console.error("Error al enviar los datos del formulario:", error);
    }
  };

  const handleSubmitFiles = async () => {
    const url = `${backUrl}/upload`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ files }),
      });

      if (!response.ok) {
        throw new Error("Error al enviar los archivos");
      }

      const data = await response.json();

      if (!Array.isArray(data.responses) || data.responses.length === 0) {
        console.error("Formato de respuesta inesperado:", data);
        throw new Error("La respuesta del servidor no contiene datos válidos");
      }

      const responseObject = data.responses[0];
       const updatedValues = { ...formValues };
      console.log("ESTA ES LA DATA DE UPDATED VALUES", updatedValues);
      const predicted = [];
      for (const [key, value] of Object.entries(responseObject)) {
        if (value !== undefined && value !== "NULL" && value !== "") {
          updatedValues[key] = value;
          predicted.push(key);
        }
      }
      setFormValues(updatedValues);
      setPredictedFields(predicted);
    } catch (error) {
      console.error("Error al enviar los archivos:", error);
    }
  };

  const renderSection = (title, fields) => (
    <div className="document-section">
      <h4 className="document-analysis-module-title">{title}</h4>
      {fields.map((field, index) => (
        <div key={index} className="modal-field">
          <label className="document-analysis-module-related-document-name">
            {field.label}
          </label>
          {field.type === "select" ? (
            <select
              value={formValues[field.label] || ""}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  [field.label]: e.target.value,
                })
              }
              className={
                predictedFields.includes(field.label) ? "predicted" : ""
              }
            >
              {field.options.map((option, idx) => (
                <option key={idx} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          ) : field.type === "checkbox" ? (
            <input
              type="checkbox"
              name={field.label}
              checked={formValues[field.label] || false}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  [field.label]: e.target.checked,
                })
              }
            />
          ) : (
            <input
              type={field.type}
              value={formValues[field.label] || ""}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  [field.label]: e.target.value,
                })
              }
              readOnly={field.readOnly}
              className={
                predictedFields.includes(field.label) ? "predicted" : ""
              }
            />
          )}
        </div>
      ))}
    </div>
  );

  const renderFilePreviewModal = () => (
    <Modal
      isOpen={isPreviewModalOpen}
      onRequestClose={() => setIsPreviewModalOpen(false)}
      className="file-preview-modal"
      overlayClassName="ReactModal__Overlay"
    >
      <h2>Previsualización de Archivos</h2>
      <div className="file-preview">
        {files.map((file, index) => (
          <div key={index} className="file-item">
            <p>{file.name}</p>
            {/* Add more preview logic if needed */}
          </div>
        ))}
      </div>
      <button className="document-analysis-module-button" onClick={handleSubmitFiles}>
        Enviar
      </button>
      {loading && <div className="loading-spinner">Cargando...</div>} {/* Loading indicator */}
    </Modal>
  );

  const formSections = [
    {
      title: "Archivos",
      fields: [
        { label: "Archivos", type: "file", multiple: true, onChange: handleFileChange },

      ],
    },
    {
      title: "Identificación",
      fields: [
        { label: "Radicado", type: "text", readOnly: true, value: radicado },
        { label: "Fecha de Ingreso", type: "text", readOnly: true, value: getCurrentDate() },
        { label: "Prioridad", type: "select", options: priorityOptions },
        { label: "Grupo", type: "select", options: groupOptions },
        { label: "Clase Correspondencia", type: "select", options: classesOptions },
        { label: "Tipificación", type: "select", options: tipificationOptions },
        { label: "Medio Recepción", type: "select", options: documentOptions },
      ],
    },
    {
      title: "Destinatario (Usuario)",
      fields: [
        { label: "Para", type: "select", options: employees },
        { label: "Dependencia", type: "select", options: dependenceOptions },
        { label: "Asunto", type: "text" },
        { label: "No. Hojas", type: "number" },
        { label: "Número de Comunicación", type: "number" },
      ],
    },
    {
      title: "Remitente",
      fields: [
        { label: "Compañía", type: "text", readOnly: true },
        { label: "Nombre Remitente", type: "text" },
        { label: "Tipo de Documento", type: "select", options: documentTypeOptions },
        { label: "Número de Documento", type: "number" },
        { label: "Dirección", type: "text" },
        { label: "Email", type: "email" },
        { label: "No. Celular", type: "number" },
        { label: "País", type: "text", readOnly: true },
        { label: "Ciudad", type: "select", options: colombianCities },
        { label: "Fecha de Remisión", type: "date" },
        { label: "Medio de Respuesta", type: "select", options: responseMediumOptions },
        { label: "Copias del Rótulo", type: "number" },
        { label: "Rótulo Automático", type: "checkbox" },
        { label: "Rótulo Papel", type: "checkbox" },
        { label: "Requiere Respuesta", type: "checkbox" },
      ],
    },
  ];

  return (
    <>
      <h1 className="business-viewpanel-title">
      Gobernación del Valle
    </h1>
    <div className="document-actions-container-2">
      {formSections.map((section, index) => (
        <div key={index} className="section-divider">
          {renderSection(section.title, section.fields)}
        </div>
      ))}
      {renderFilePreviewModal()}
      {loading && <div className="loading-spinner">Cargando...</div>} {/* Loading indicator */}
      <button className="create-new-employee-button" onClick={handleSubmitForm}>
        Crear Radicado
      </button>
      {/* <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className="document-analysis-module-container"
        overlayClassName="ReactModal__Overlay"
        centered
      >
        <ModalBody className="business-extraction-modal-body">
          <h2>Adjunta o escanea los archivos del radicado</h2>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
             <label htmlFor="choose-files" className="document-analysis-module-button" style={{ marginRight: '10px' }}>
               Elegir archivos
             </label>
             <input
               id="choose-files"
               type="file"
               className="document-analysis-module-input"
               multiple
               onChange={handleFileChange}
               style={{ display: 'none' }}
             />
             <label htmlFor="scan-files" className="document-analysis-module-button">
               Escanear archivos
             </label>
             <input
               id="scan-files"
               type="file"
               className="document-analysis-module-input"
               multiple
               onChange={handleFileChange}
               style={{ display: 'none' }}
             />
           </div>
           <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button className="document-analysis-module-button" onClick={handleSubmitFiles}>Enviar</button>
           </div>
        </ModalBody>
      </Modal> */}
      </div>
    </>
  );
}

export default DocumentActions;