// src/MantisBusiness/UserNotification.jsx
import React, { useEffect, useState } from "react";
import { getExtractedData } from "../ApiCalls/mantisBusiness";
import { Row, Col, Spinner } from "reactstrap";
import "./styles/businessBackofficeStyles.scss";

function UserNotification({ mantisBusiness, token, user }) {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (!mantisBusiness || !token || !user) return;

      const response = await getExtractedData(
        mantisBusiness._id,
        token,
        "rateConfirmations",
        1, // currentPage
        10, // registersPerPage
        "EXTERNAL", // tab
        user._id
      );

      if (response.success) {
        const today = new Date().toISOString().split('T')[0];
        const newCases = response.data.filter((data) => data.fecha_radicado === today);

        if (newCases.length > 0) {
          setNotifications((prevNotifications) => [
            ...prevNotifications,
            ...newCases.map((caseItem) => ({
              message: `Nuevo caso: ${caseItem.categoria}`,
              date: new Date(),
            })),
          ]);
        }
      } else {
        console.error("Error al obtener los datos:", response.message);
      }
      setLoading(false);
    };

    fetchData();
  }, [mantisBusiness, token, user]);

  return (
    <div>
      <Row>
        <Col xs={11} style={{ margin: "auto" }}>
          <h1 className="notifications-title">Notificaciones</h1>
          {loading ? (
            <Spinner color="#282828" />
          ) : notifications.length === 0 ? (
            <h3 className="business-notifications-date">
              ¡No tienes notificaciones por el momento!
            </h3>
          ) : (
            notifications.map((notification, index) => (
              <div key={index} className="notification-item">
                <h4 className="business-notification-subject">
                  {notification.message}
                </h4>
                <p className="business-notification-message">
                  {notification.date.toLocaleString()}
                </p>
              </div>
            ))
          )}
        </Col>
      </Row>
    </div>
  );
}

UserNotification.defaultProps = {
  mantisBusiness: {},
  token: '',
  user: {},
};

export default UserNotification;